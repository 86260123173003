import React from "react";
import styles from "../styles/legal.module.scss";
import InstantNewsLogo from "../components/imageComponents/instantNewsLogo";

const IndexPage = () => {
  return (
    <div>
      <div className={styles.nav}>
        <div className={styles.logo}>
          <InstantNewsLogo />
        </div>
        <div className={styles.header}>Privacy Policy</div>
      </div>

      <div className={styles.privacyWrapper}>
        <div className={styles.privacyContainer}>
          <p>
            <b>1. Our Commitment to Data Privacy</b>
          </p>
          <p>
            The security of personal information relating to our clients and other end users is
            treated as a matter of critical importance within Instant News extension (Conducive
            Search).
          </p>
          <p>
            Conducive Search follows industry best practices for stewardship of data, including
            transparency, accountability and choice regarding the collection, use, and disclosure of
            information. Conducive Search is committed to protecting the data from any users of our
            services and our partners and maintaining compliance with all applicable laws, including
            the EU’s General Data Protection Regulation ("GDPR").
          </p>
          <p>
            As our company, technology and services evolve, this Privacy Notice may be revised to
            reflect those changes. Notice of such amendments will be posted as an updated Privacy
            Notice on this website. Please review this Privacy Notice periodically in order to keep
            up to date with any modifications.
          </p>

          <p className={styles.mt5}>
            <b>2. Who is Conducive Search?</b>
          </p>
          <p>
            The entity that decides how and for what purpose data is processed (also referred to as
            a "data controller") is:
          </p>
          <p className={styles.mt0}>Conducive Search. d.b.a adMarketplace, Inc.</p>
          <p className={styles.m0}>1385 Broadway, 19th Floor</p>
          <p className={styles.m0}>New York, NY 10018</p>
          <p className={styles.m0}>USA</p>
          <p className={styles.m0}>Tel. +1 212 925 2022</p>
          <p className={styles.m0}>Email: legal@conducivesearch.com; privacy@admarketplace.com</p>

          <p className={styles.mt5}>
            <b>3. Purposes of and Legal Bases for Data Processing</b>
          </p>
          <p>
            Conducive Search uses personal data relating to identifiable individuals ("personal
            data") as set out below:
          </p>
          <ul className={styles.mt3}>
            <li>
              <u>If you are an internet user visiting one of our websites:</u>
              We process personal data in order to deliver the most relevant ads to you. Our hosted
              properties have a search box where you can enter search queries and our technology
              platform shows ads in the following ways:
              <ul className={styles.ml5}>
                <li>
                  Conducive Search. During your search: we show relevant, "instant search" links for
                  News Partners and Brands based on what you are typing and these links allow you to
                  directly navigate to the news or brand site and ;
                </li>
                <li>
                  Search Results: You will see your search results and related sponsored links from
                  News Partners and Brands;
                </li>
                <li>
                  Omnibox Redirect: we will auto-complete and/or redirect your mistypes and
                  misspells to the News Sources or Brand URL that we believe you intended to
                  navigate to.
                </li>
              </ul>
            </li>
            <li>
              We ensure ads comply with applicable regulations and our obligations, to provide our
              customers with reports to analyze their campaigns (such as which ads were delivered
              and the number of clicks), and to detect and prevent fraud.
              <br />
              Using IP addresses helps us ensure ads are running in permitted territories, to better
              place ads, and flag fraudulent traffic. Cookies may be used to track how you interact
              with the advertisement (please see section 10 below).
              <br />
              Our legal basis for this processing is:
              <ul className={styles.ml5}>
                <li>
                  For display of advertising: your consent (which you give to Conducive Search when
                  you visit a Conducive Search site); and
                </li>
                <li>
                  For analytics and fraud prevention: that data processing is necessary for our
                  legitimate interests and those of our clients, namely improving our products and
                  services, charging correctly for our services, and reducing fraud.
                </li>
              </ul>
            </li>
            <li>
              <u>
                If you are a visitor to our website: our legal basis for processing your personal
                data is:
              </u>
              <ul className={styles.ml5}>
                <li>
                  When you contact us with inquiries, complaints or request other assistance: your
                  consent; and
                </li>
                <li>
                  For technical data relating to your device/network: that data processing is
                  necessary for our legitimate interests, namely improving our website and products
                  and services.
                </li>
              </ul>
            </li>
            <li>
              <u>If you work for a customer or supplier (or potential customer or supplier):</u>
              <ul className={styles.ml5}>
                <li>
                  Where we provide products or services to you or purchase products or services from
                  you: That it is necessary for the performance of our contracts with our customers
                  or suppliers;
                </li>
                <li>
                  Where you are an authorized user of our online customer portal: in order to
                  provide access to materials and product features, this being necessary for
                  performing our contract with you or for our legitimate interests in managing our
                  relationship with you;
                </li>
                <li>
                  Where we send newsletters or other commercial offers: that it is necessary for our
                  legitimate interests in managing and developing our business;
                </li>
                <li>
                  Where we keep data for legal, recordkeeping, tax or accounting purposes: that it
                  is necessary to enable us to comply with the legal obligations that apply to our
                  business.
                </li>
              </ul>
            </li>
            <li>
              This Privacy Notice does not apply to personal data processing that relates to our
              employees or other staff, or to applicants for positions with any of our group of
              companies, as these activities are governed by separate privacy notices. Please
              contact our Legal and HR department for more information at legal@conducivesearch.com.
            </li>
          </ul>

          <p className={styles.mt5}>
            <b>4. What Personal Data do we Collect?</b>
          </p>
          <ul className={styles.mt3}>
            <li>
              Search information
              <br />
              Conducive Search’s network shows ads to visitors of our websites. We receive and
              analyze the search terms (keywords) entered into the search box on those sites. We may
              also receive technical information such as your approximate location, browser type,
              language settings, user agent, timestamp, cookie ID and IP addresses. We also receive
              information regarding which ads were viewed or clicked on and that you arrived on or
              made a purchase from one of our advertiser’s websites.
              <br />
              When a user types a search query, we will match ads between the current user agent and
              IP address and the search query, and those ads will appear in the scroll down menu
              below the search box. If the user clicks on the ad, we will collect the click
              information (along with ad information…), then a cookie will be dropped on the user’s
              device. If the user proceeds to the site redirected from the ad he clicked on, and if
              the user then purchases a product or a service or completes an action during that
              session, then a conversion pixel might be fired from the advertiser site. This helps
              Conducive Search and the advertiser measure the effectiveness of its advertising.
              <br />
              When the user uses our products in a mobile environment, we might sometimes collect
              the device IDs (unique identifiers to the phone the user is using).
            </li>
            <li>
              Location information
              <br />
              We collect the user’s location information and send it to our weather information
              provider so that the user receives relevant weather information. We do not store or
              sell the user’ location data, nor do we share it with any other third party.
            </li>
            <li>
              Website
              <br />
              On our "contact us" links, an email address ({" "}
              <a href="mailto:info@conducivesearch.com">info@conducivesearch.com</a> ) is provided
              so that you can input your name and contact details. We collect this information so
              that we can respond to the comments or questions that you send us. If you do not
              provide us with this information we may not be able to fully complete your request. We
              do not merge any such information collected via our email contacts with other data
              collected on our website.
              <br />
              In order to maintain and manage our website, we record other information such as IP
              addresses, page views and clicks, and cookies for gathering website statistics. We
              also store IP addresses on our server in the event that our site becomes corrupted and
              it becomes necessary to discover the cause.
            </li>
            <li>
              Business Contacts
              <br />
              We collect billing and contact information in order to administer our customers’
              accounts, and fulfill the services they request, and also when we are evaluating or
              purchasing goods and services from new or existing vendors.
              <br />
              In order to provide certain services, we may also collect information from you when
              you attend one of our events, during phone calls with sales representatives, or when
              you contact a Conducive Search representative. We may on occasion supplement the
              information you submitted to us with information from third party sources to verify
              your address as part of our anti-fraud effort.
              <br />
              We may also obtain information from public sources, such as professional networking
              platforms, trade shows, or other directories, as part of our business development
              activities.
            </li>
          </ul>

          <p className={styles.mt5}>
            <b>5. Security</b>
          </p>
          <ul className={styles.mt3}>
            <li>
              Conducive Search applies commercially reasonable security measures to protect data
              from unauthorized access, corruption or loss. These include storing information in
              databases which are protected by security measures such as password protection and
              encryption at rest using Transport Layer Security technology (TLS), so that even if
              the data is improperly accessed, it is less likely to be in an intelligible format.
              When no longer required, this data is moved to our secure archive server, also
              encrypted and password protected and only accessed for regulatory, legal or business
              reasons.
            </li>
          </ul>

          <p className={styles.mt5}>
            <b>6. Third Parties</b>
          </p>
          <ul className={styles.mt3}>
            <li>
              We may provide data for specific purposes to data processors (e.g. for storage,
              analytics, or fraud detection), especially our publisher partners, agencies and
              advertisers. Our contracts for these services comply with the Privacy Shield
              Principles for onward data transfers, including obligations to provide the same level
              of protection.
            </li>
            <li>
              We may also disclose your personal information, where we believe this to be necessary
              or appropriate: (a) under applicable local, national, or international laws; (b) to
              comply with legal processes; (c) to respond to requests from any public and government
              authorities; (d) to enforce our terms and conditions; (e) to protect our operations or
              those of any of our group companies; (f) to protect our rights, privacy, safety or
              property, and/or that of our group companies, you or others; and (g) to allow us to
              pursue available remedies or limit the damages that we may sustain.
            </li>
            <li>
              Although this website contains links to other websites, please be aware that we do not
              endorse and are not responsible for the privacy practices of those, or any other
              sites. If you follow a link to another website you should carefully read their privacy
              notice and terms and conditions.
            </li>
            <li>
              Although this website contains links to other websites, please be aware that we do not
              endorse and are not responsible for the privacy practices of those, or any other
              sites. If you follow a link to another website you should carefully read their privacy
              notice and terms and conditions. Publishers, agencies or advertisers with whom we work
              may use additional cookies, web beacons and other technology to collect information
              about you, including for advertising purposes. Conducive Search does not control
              publishers’ or advertisers’ use of such technology. To learn about how a particular
              publisher may use your information, you should read their privacy notices and terms
              and conditions.
            </li>
          </ul>

          <p className={styles.mt5}>
            <b>7. International Data Transfers</b>
          </p>
          <ul className={styles.mt3}>
            <li>
              Conducive Search stores and process data in the USA. Conducive Search complies with
              the requirements of the EU-U.S. Privacy Shield Frameworks as set forth by the U.S.
              Department of Commerce regarding the collection, use, and retention of personal
              information from customers in the European Union and EEA member countries. Data from
              the UK is specifically included for such purposes. For the purposes of enforcing
              compliance with the Privacy Shield, we are subject to the investigatory and
              enforcement authority of the U.S. Federal Trade Commission. Conducive Search has
              certified that it adheres to the Privacy Shield Principles of notice, choice,
              accountability for onward transfer, security, data integrity and purpose limitation,
              access, recourse, enforcement and liability.
            </li>
          </ul>

          <p className={styles.mt5}>
            <b>8. Minors</b>
          </p>
          <ul className={styles.mt3}>
            <li>
              Conducive Search does not knowingly or specifically collect information about children
              under the age of 13. If you believe that we have mistakenly or unintentionally
              collected such information, please notify us at{" "}
              <a href="mailto:legal@conducivesearch.com">legal@conducivesearch.com</a>
            </li>
          </ul>

          <p className={styles.mt5}>
            <b>9. Retention of Data</b>
          </p>
          <ul className={styles.mt3}>
            <li>
              For internet users to whom we show ads, we keep this for up to thirteen (13) months
              for advertising purposes. Aggregated and anonymized or statistical data may be
              retained for a longer period for reporting to and billing our customers.
              <br />
              For website visitors we may retain data for as long as it takes to answer your
              queries. We may also continue to use data in aggregated and anonymized form to analyze
              and improve our website and products.
              <br />
              For business contacts, data will normally be kept for the duration of any contractual
              relationship and for six years after it ends (in order to be in a position to address
              any legal claims that could be made). We may need to keep data for longer for legal
              compliance, accounting, tax or recordkeeping purposes, in which case it will be kept
              and used only for such purposes.
              <br />
              If we don’t have a contractual relationship then we will regularly review the data and
              delete it when no longer necessary for the original purpose, but we will retain it for
              as long as we are in contact (e.g. if you have subscribed to a newsletter or other
              publications from us, or we are evaluating a business relationship).
            </li>
          </ul>

          <p className={styles.mt5}>
            <b>10. Cookies</b>
          </p>
          <ul className={styles.mt3}>
            <li>
              A cookie is a small piece of data sent from a website and stored in a user’s web
              browser. When the user loads a website that is set up to react to that cookie, the
              browser sends cookie information to the server and the website that it recognizes the
              user.
              <br />
              You can find more information about different types of cookies at{" "}
              <a href="https://www.allaboutcookies.org">allaboutcookies.org</a>
              .
              <br />
              We or our publishers may use cookies for the purposes of showing and measuring ads on
              our publishers’ webpages. We don’t "retarget" you by continuing to show ads on other
              websites that you later visit.
              <br />
              If you no longer want to allow us to use your data through the use of cookies or
              similar technologies, you can remove cookies from your web browser by following the
              directions provided in your browser’s "help" section. Remember that some cookies are
              essential for using different features of certain websites, so if you clear all
              cookies then those features may not work as expected, or you may need to accept some
              cookies on your next visit.
              <br />
              For opting out from mobile devices please review your privacy control settings. For
              more information visit:{" "}
              <a href="https://www.networkadvertising.org/mobile-choice">
                www.networkadvertising.org/mobile-choice
              </a>
            </li>
          </ul>

          <p className={styles.mt5}>
            <b>11. GDPR Rights</b>
          </p>
          <ul className={styles.mt3}>
            <li>
              If you are in the EEA, including in the EU or the UK, you have various rights in
              relation to your personal data which may include the rights:
              <ul className={styles.ml5}>
                <li>to request access to it</li>
                <li>to request rectification of incorrect data</li>
                <li>to ask for it to be erased (the "right to be forgotten")</li>
                <li>to object to its processing</li>
                <li>to ask for a copy to take to another service provider ("data portability").</li>
              </ul>
            </li>
            <li>
              If our data processing is based on your consent, then you can always withdraw that
              consent (but any processing that took place before withdrawal will still be legal).
              <br />
              If you request that data should be erased, the usual method for this is to remove all
              personally identifying elements so that the data is permanently anonymized and cannot
              be traced back to you.
              <br />
              In case of any issue, please contact us using the details in this Notice and we will
              help as far as we can. If you believe that we have not respected your rights then you
              also have a right to make a complaint to the Supervisory Authority in your country
              (e.g. in the UK, the Information Commissioner’s Office:{" "}
              <a href="https://ico.org.uk/">ico.org.uk</a> ).
              <br />
              You may ask to exercise any of your rights by submitting a request to:{" "}
              <a href="mailto:privacy@admarketplace.com">privacy@admarketplace.com.</a>
            </li>
          </ul>

          <p className={styles.mt5}>
            <b>12. EU-U.S. Privacy Shield</b>
          </p>
          <ul className={styles.mt3}>
            <li>
              To learn more about the Privacy Shield program, and to view our certification page,
              please visit <a href="https://www.privacyshield.gov">www.privacyshield.gov.</a>
            </li>
            <li>
              Any questions or complaints concerning our Privacy Shield compliance may be directed
              to privacy@admarketplace.com. If we have not been able to satisfactorily resolve the
              issue, then, you may submit your complaint to JAMS using this link:{" "}
              <a href="https://www.jamsadr.com/eu-us-privacy-shield">
                www.jamsadr.com/eu-us-privacy-shield
              </a>
              , adMarketplace’s designated Privacy Shield dispute resolution provider. Under certain
              conditions specified by the applicable Privacy Shield Principles, you may also be able
              to invoke binding arbitration to resolve your complaint.
            </li>
          </ul>
          <p className={styles.mt5}>
            <b>13. CCPA</b>
          </p>
          <p>Your rights under the CCPA:</p>
          <p>
            Your rights under the CCPA can broadly be divided into the following categories: (1)
            right to notice, (2) right to access, (3) right to opt out (or right to opt in), (4)
            right to request deletion, and (5) right to equal services and prices.
          </p>
          <p>
            Right to notice: Under the CCPA, businesses must inform consumers at or before the point
            of collection what categories of personal information will be collected and the purposes
            for which these categories will be used.
          </p>
          <p>
            Right to access: Consumers have the right to request that a business disclose the
            categories of personal information collected; the categories of sources from which
            personal information is collected; the business or commercial purpose; the categories of
            third parties with which the business shares personal information; and the specific
            pieces of personal information the business holds about a consumer. If a business sells
            personal information or discloses it for business purposes, consumers have the right to
            request the categories of information so sold or disclosed.
          </p>
          <p>
            Right to opt-out: Consumers have the right—at any time—to direct businesses that sell
            personal information about the consumer to third parties to stop this sale, known as the
            right to opt-out. If a consumer is a minor, the CCPA provides for a right to opt-in to
            the sale of data (exercised by the minor if the consumer is between 13 and 16 years of
            age, or by the minor’s parent or guardian if the consumer is under 13 years old).
            Businesses must wait at least 12 months before asking consumers to opt back in.
          </p>
          <p>
            Right to request deletion: Consumers also have the right to request the deletion of
            personal information, but only where that information was collected from the consumer.
            Like the right to erasure under the GDPR, this right is subject to exceptions. For
            instance, businesses need not delete personal information necessary for detecting
            security incidents, exercising free speech, protecting or defending against legal
            claims, or—in what is potentially a broad and likely contentious category—for internal
            uses reasonably aligned with the consumer’s expectations.
          </p>
          <p>
            Right to equal services and prices: The CCPA prohibits businesses from discriminating
            against consumers by denying goods or services, charging a different price or rate for
            goods or services, providing a different level or quality of goods or services, or
            suggesting that they will do any of these things based upon a consumer’s exercise of any
            CCPA rights. Put differently, consumers have a right to equal services and prices.
          </p>

          <p>Exercising your rights under the CCPA:</p>

          <p>
            According the law (see paragraph above), you are entitled to request specific pieces of
            personal information (as defined under the CCPA) that we have about you, the business
            purpose for which the personal information was collected, categories of personal
            information, categories of sources from which the personal information was collected,
            categories of personal information that we either shared, transferred, sold with a
            business purpose, categories of those third parties to which the personal information
            was sold for a business purpose.
          </p>
          <p>
            If you want to do such a request, you can write to{" "}
            <a href="mailto:privacy@admarketplace.com">privacy@admarketplace.com</a> and we will
            respond within 45 days as per the law.
          </p>
          <p>We reserve the right to verify your identity if you make such a request.</p>
          <p>
            However, we may not be able to respond to your request if it goes against applicable
            law.
          </p>
          <p>
            Please note that we do transfer personal information collected through our network of
            partners to third parties and as such are considered as having disclosed or sold data
            (as defined under the CCPA) over the past twelve months.
          </p>
          <p>
            <a
              href="https://www.admarketplace.com/contact/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Opt-Out Request Form
            </a>
            <br />
            <b>Updated: August 12, 2020 </b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
